class Dialogs {
    async alert(message) {
        return new Promise(function(resolve) {
            bootbox.alert(message, function() {
                resolve()
            })
        })
    }
    async confirm(message) {
        return new Promise(function(resolve) {
            bootbox.confirm(message, function(res) {
                resolve(res)
            })
        })
    }
}

export default Dialogs