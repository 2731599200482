import Vue from 'vue'

import Modal from './modal'
import Pagination from './pagination'
import Datetimepicker from './datetimepicker'
import Upload from './upload'
//import Datepicker from 'vuejs-datepicker';

Vue.component('modal', Modal)
Vue.component('pagination', Pagination)
Vue.component('datetimepicker', Datetimepicker)
Vue.component('upload', Upload)