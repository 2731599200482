import Dashboard from '../controllers/secured/dashboard'
import Roles from '../controllers/secured/roles'
import Usuarios from '../controllers/secured/usuarios'
import CambiarPassword from '../controllers/secured/cambiar-password'
import Correspondencia from '../controllers/secured/correspondencia'
import Documentos from '../controllers/secured/documentos'
import TiposCorrespondencia from '../controllers/secured/tipos-correspondencia'
import Oficinas from '../controllers/secured/oficinas'
import Rpt01Importaciones from '../controllers/secured/rpt01Importaciones'
import Rpt02Exportaciones from '../controllers/secured/rpt02Exportaciones'
import Rpt03DTA from '../controllers/secured/rpt03DTA'
import Rpt04AuditoriaDI from '../controllers/secured/rpt04AuditoriaDI'
import Rpt05Cotecmar from '../controllers/secured/rpt05Cotecmar'
import Rpt06Temporales from '../controllers/secured/Rpt06Temporales'
import Rpt07Gerencial from '../controllers/secured/Rpt07Gerencial'
import Rpt08VerificaCapital from '../controllers/secured/Rpt08VerificaCapital'
import Rpt09Pendientes from '../controllers/secured/Rpt09Pendientes'
import Rpt10Archivo from '../controllers/secured/Rpt10Archivo'
import Rpt11Archivador from '../controllers/secured/Rpt11Archivador'

export default [
    {
        path: '',
        name: 'dashboard',
        component: Dashboard
    },
    {
        path: 'usuarios',
        name: 'usuarios',
        component: Usuarios
    },
    {
        path: 'roles',
        name: 'roles',
        component: Roles
    },
    {
        path: 'cambiar-password',
        name: 'cambiar-password',
        component: CambiarPassword
    },
    {
        path: 'correspondencia',
        name: 'correspondencia',
        component: Correspondencia
    },
    {
        path: 'correspondencia/:id/documentos',
        name: 'documentos',
        component: Documentos
    },
    {
        path: 'tipos-correspondencia',
        name: 'tipos-correspondencia',
        component: TiposCorrespondencia
    },
    {
        path: 'oficinas',
        name: 'oficinas',
        component: Oficinas
    },
    
    {
        path: 'rpt01Importaciones',
        name: 'rpt01Importaciones',
        component: Rpt01Importaciones
    },
    {
        path: 'rpt02Exportaciones',
        name: 'rpt02Exportaciones',
        component: Rpt02Exportaciones
    },
    {
        path: 'rpt03DTA',
        name: 'rpt03DTA',
        component: Rpt03DTA
    },
    {
        path: 'rpt04AuditoriaDI',
        name: 'rpt04AuditoriaDI',
        component: Rpt04AuditoriaDI
    },
    {
        path: 'rpt05Cotecmar',
        name: 'rpt05Cotecmar',
        component: Rpt05Cotecmar
    },
    {
        path: 'rpt06Temporales',
        name: 'rpt06Temporales',
        component: Rpt06Temporales
    },
    {
        path: 'rpt07Gerencial',
        name: 'rpt07Gerencial',
        component: Rpt07Gerencial
    },
    {
        path: 'rpt08VerificaCapital',
        name: 'rpt08VerificaCapital',
        component: Rpt08VerificaCapital
    },
    {
        path: 'rpt09Pendientes',
        name: 'rpt09Pendientes',
        component: Rpt09Pendientes
    },
    {
        path: 'rpt10Archivo',
        name: 'rpt10Archivo',
        component: Rpt10Archivo
    },
    {
        path: 'rpt11Archivador',
        name: 'rpt11Archivador',
        component: Rpt11Archivador
    },
]

