<template>
  <div class="mt-2">
    <h3>Oficinas <small v-if="$_.includes($auth.permisos, 'agregar_oficinas')"><a href="" @click.prevent="agregar" title="Agregar">[Agregar]</a></small></h3>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="oficina in oficinas" :key="oficina.id">
                <td>{{ oficina.nombre }}</td>
                <td>
                  <a href="" @click.prevent="editar(oficina)" title="Editar"><i class="fas fa-edit fa-lg"></i></a>
                  <a href="" @click.prevent="eliminar(oficina)" title="Eliminar"><i class="fas fa-trash fa-lg"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="cambiarPagina"></pagination>
      </div>
    </div>
    <form @submit.prevent="guardar">
      <modal v-model="modal">
        <div slot="header" class="modal-header">
          <h4 class="modal-title">Oficinas</h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label class="form-control-label" for="oficina-nombre">Nombre:</label>
            <input type="text" class="form-control" id="oficina-nombre" placeholder="Nombre" v-model="oficina.nombre">
          </div>
        </div>
        <div slot="footer" class="modal-footer">
          <button type="submit" class="btn btn-success">Guardar</button>
          <button type="button" class="btn btn-secondary" @click="modal = false">Salir</button>
        </div>
      </modal>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

import { url, dialogs } from '../../services'

export default {
  data() {
    return {
      modal: false,
      oficinas: [],
      oficina: {
      },
      id: null,
      pagination: {
        currentPage: 1,
        pages: 1
      }
    }
  },
  methods: {
    agregar() {
      this.id = null
      this.oficina = {
      }
      this.modal = true
    },
    editar(rol) {
      this.id = rol.id
      this.oficina = {
        nombre: rol.nombre,
      }
      this.modal = true
    },
    async eliminar(oficina) {
      let res = await dialogs.confirm(
        `Esta seguro de que desea borrar la oficina ${oficina.nombre}`
      )
      if (res) {
        try {
          await axios.delete(url.create(`/oficinas/${oficina.id}`))
          PNotify.notice('Oficina eliminada correctamente')
          await this.listar()
        } catch (ex) {}
      }
    },
    async guardar() {
      try {
        let response = this.id
          ? await axios.put(url.create(`/oficinas/${this.id}`), this.oficina)
          : await axios.post(url.create('/oficinas'), this.oficina)
        PNotify.notice('Oficina guardada correctamente')
        await this.listar()
        this.modal = false
      } catch (ex) {}
    },
    async listar() {
      try {
        let response = await axios.get(url.create('/oficinas'), {
          params: { page: this.pagination.currentPage }
        })
        this.oficinas = response.data.data
        this.pagination.pages = Math.ceil(
          response.data.total / response.data.per_page
        )
      } catch (ex) {}
    },
    cambiarPagina(page) {
      this.currentPage = page
      this.listar()
    },
  },
  mounted() {
    this.listar()
  }
}
</script>
