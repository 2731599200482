<template>
  <div class="mt-2">
    <h3>Correspondencia</h3>
    <div class="row mb-2" v-if="correspondencia">
      <div class="col-md-12">
        <div class="border border-info rounded-lg px-2">
          <dl class="row">
            <dt class="col-sm-3">Número de guía</dt>
            <dd class="col-sm-9">
              {{ correspondencia.numero_guia }}
            </dd>
            <dt class="col-sm-3">Remitente</dt>
            <dd class="col-sm-9">
              {{ correspondencia.remitente }}
            </dd>
            <dt class="col-sm-3">Destinatario</dt>
            <dd class="col-sm-9">
              {{ correspondencia.destinatario ? correspondencia.destinatario.nombre : 'ND' }}
            </dd>
            <dt class="col-sm-3">Tipo</dt>
            <dd class="col-sm-9">
              {{ correspondencia.tipo_correspondencia ? correspondencia.tipo_correspondencia.nombre : 'ND' }}
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <h3>Documentos <small v-if="$_.includes($auth.permisos, 'agregar_documentos')"><a href="" @click.prevent="agregar" title="Agregar">[Agregar]</a></small></h3>
    <div class="row mb-2">
      <form class="col-md-12">

      </form>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'NIT') >= 0">NIT</th>
                <th v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'RAZON_SOCIAL') >= 0">Razón social</th>
                <th v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'DO') >= 0">DO</th>
                <th v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'NO_REQUERIMIENTO') >= 0">No. de requerimiento</th>
                <th v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'FECHA_REQUERIMIENTO') >= 0">Fecha de  requerimiento</th>
                <th v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'FECHA_VENCIMIENTO') >= 0">Fecha de vencimiento</th>
                <th v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'DESCRIPCION') >= 0">Descripción</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="documento in documentos" :key="documento.id">
                <td>{{ documento.nombre }}</td>
                <td v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'NIT') >= 0">{{ documento.nit }}</td>
                <td v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'RAZON_SOCIAL') >= 0">{{ documento.razon_social }}</td>
                <td v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'DO') >= 0">{{ documento.do }}</td>
                <td v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'NO_REQUERIMIENTO') >= 0">{{ documento.no_requerimiento }}</td>
                <td v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'FECHA_REQUERIMIENTO') >= 0">{{ documento.fecha_requerimiento }}</td>
                <td v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'FECHA_VENCIMIENTO') >= 0">{{ documento.fecha_vencimiento }}</td>
                <td v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'DESCRIPCION') >= 0">{{ documento.descripcion }}</td>
                <td>
                  <a href="" @click.prevent="editar(documento)" title="Editar" v-if="$_.includes($auth.permisos, 'editar_documentos')"><i class="fas fa-edit fa-lg"></i></a>
                  <a href="" @click.prevent="eliminar(documento)" title="Eliminar" v-if="$_.includes($auth.permisos, 'eliminar_documentos')"><i class="fas fa-trash fa-lg"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <form @submit.prevent="guardar">
      <modal v-model="modal">
        <div slot="header" class="modal-header">
          <h4 class="modal-title">Documentos</h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label class="form-control-label" for="documento-nombre">Nombre:</label>
            <input type="text" class="form-control" id="documento-nombre" placeholder="Nombre" v-model="documento.nombre">
          </div>
          <div class="form-group" v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'NIT') >= 0">
            <label class="form-control-label" for="documento-nit">NIT:</label>
            <input type="text" class="form-control" id="documento-nit" placeholder="NIT" v-model="documento.nit">
          </div>
          <div class="form-group" v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'RAZON_SOCIAL') >= 0">
            <label class="form-control-label" for="documento-razon-social">Razón social:</label>
            <input type="text" class="form-control" id="documento-razon-social" placeholder="Razón social" v-model="documento.razon_social">
          </div>
          <div class="form-group" v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'DO') >= 0">
            <label class="form-control-label" for="documento-do">DO:</label>
            <input type="text" class="form-control" id="documento-do" placeholder="DO" v-model="documento.do">
          </div>
          <div class="form-group" v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'NO_REQUERIMIENTO') >= 0">
            <label class="form-control-label" for="documento-no-requerimiento">No. de requerimiento:</label>
            <input type="text" class="form-control" id="documento-no-requerimiento" placeholder="No. de requerimiento" v-model="documento.no_requerimiento">
          </div>
          <div class="form-group" v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'FECHA_REQUERIMIENTO') >= 0">
            <label class="form-control-label" for="documento-fecha-requerimiento">Fecha del requerimiento:</label>
            <datetimepicker type="text" id="documento-fecha-requerimiento" placeholder="Fecha del requerimiento" v-model="documento.fecha_requerimiento"></datetimepicker>
          </div>
          <div class="form-group" v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'FECHA_VENCIMIENTO') >= 0">
            <label class="form-control-label" for="documento-fecha-vencimiento">Fecha de vencimiento:</label>
            <datetimepicker type="text" id="documento-fecha-vencimiento" placeholder="Fecha de vencimiento" v-model="documento.fecha_vencimiento"></datetimepicker>
          </div>
          <div class="form-group" v-if="$_.indexOf($_.get(correspondencia, 'tipo_correspondencia.campos'), 'DESCRIPCION') >= 0">
            <label class="form-control-label" for="documento-descripcion">Descripción:</label>
            <textarea class="form-control" id="documento-descripcion" placeholder="Descripción" v-model="documento.descripcion"></textarea>
          </div>
        </div>
        <div slot="footer" class="modal-footer">
          <button type="submit" class="btn btn-success">Guardar</button>
          <button type="button" class="btn btn-secondary" @click="modal = false">Salir</button>
        </div>
      </modal>
    </form>
  </div>
</template>

<script>
import { url, dialogs } from '../../services'

export default {
  data() {
    return {
      modal: false,
      correspondencia: {},
      tipos: [],
      documentos: [],
      documento: {},
      id: null
    }
  },
  methods: {
    agregar() {
      this.id = null
      this.documento = {
      }
      this.modal = true
    },
    editar(documento) {
      this.id = documento.id
      this.documento = {
        nombre: documento.nombre,
        nit: documento.nit,
        razon_social: documento.razon_social,
        do: documento.do,
        no_requerimiento: documento.no_requerimiento,
        fecha_requerimiento: documento.fecha_requerimiento,
        fecha_vencimiento: documento.fecha_vencimiento,
        descripcion: documento.descripcion,
      }
      this.modal = true
    },
    async eliminar(documento) {
      let res = await dialogs.confirm(
        `Esta seguro de que desea borrar el documento ${documento.nombre}`
      )
      if (res) {
        try {
          await axios.delete(
            url.create(
              `/correspondencia/${this.$route.params.id}/documentos/${documento.id}`
            )
          )
          PNotify.notice('Documento eliminado correctamente')
          await this.listar()
        } catch (ex) {}
      }
    },
    async guardar() {
      try {
        let response = this.id
          ? await axios.put(
              url.create(
                `/correspondencia/${this.$route.params.id}/documentos/${this.id}`
              ),
              this.documento
            )
          : await axios.post(
              url.create(
                `/correspondencia/${this.$route.params.id}/documentos`
              ),
              this.documento
            )
        PNotify.notice('Documento guardado correctamente')
        await this.listar()
        this.modal = false
      } catch (ex) {}
    },
    async listar() {
      try {
        let response = await axios.get(
          url.create(`/correspondencia/${this.$route.params.id}/documentos`)
        )
        this.documentos = response.data
      } catch (ex) {}
    },
    async obtenerCorrespondencia() {
      try {
        let response = await axios.get(
          url.create(`/correspondencia/${this.$route.params.id}`)
        )
        this.correspondencia = response.data
      } catch (ex) {}
    }
  },
  mounted() {
    this.listar()
    this.obtenerCorrespondencia()
  }
}
</script>
