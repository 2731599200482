<template>
    <section>
        <div class="row mb-2">
            <div class="col-sm-12">
                <form>
                    <div>
                        <legend>INFORME COTECMAR</legend>
                    </div>
                    <div class="form-group row">
                        <label for="filtro-nombre" class="col-sm-2 col-form-label">Cliente</label>
                        <div class="col-sm-8">
                            <select class="form-control" v-model="filtro.cliente_id" placeholder="Seleccione el cliente" v-searchable="{liveSearchStyle: 'startWith'}">
                                <option :value="undefined"></option>
                                <option v-for="cliente in clientes" :key="cliente.id" :value="cliente.id">{{ cliente.nombre }} {{ cliente.nit }}</option>
                            </select>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <button type="button" class="btn btn-success" @click="descargarXlsx">Exportar a xlsx</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'

import { url, dialogs } from '../../services'

export default {
    data() {
        return {
            clientes: [],
            errores: {                
            },
            filtro: {
                
            }
        }
    },
    methods: {
        async descargarXlsx() {
            
            try{              

                let response = await axios.get(url.create('/rpt05Cotecmar/excel'), { params: { filtro: this.filtro } });
                window.location.href = response.data.enlace;
            }
            catch(ex){
                alert(ex.response.data);
            }
        }
    },
    async created() {
        this.clientes = (await axios.get(url.create('/clientes'))).data;
    }
}
</script>
