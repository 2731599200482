<template>
  <div class="input-group date" :class="cssClass">
    <input type="text" class="form-control" :id="id" :placeholder="placeholder" :value="value">
    <!--input-group-addon: workaround para que funcione con bootstrap 4-->
    <span class="input-group-append input-group-addon">
      <span class="input-group-text">
        <i class="fas fa-calendar-alt"></i>
      </span>
    </span>
  </div>
</template>

<script>
import 'pc-bootstrap4-datetimepicker'

export default {
  name: 'DateTimePicker',
  props: {
    value: {
      type: String
    },
    id: {
      type: String
    },
    cssClass: {
      type: String
    },
    placeholder: {
      type: String
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    minDate: {
      default: false
    },
    maxDate: {
      default: false
    },
    locale: {
      type: String,
      default: 'es'
    }
  },
  watch: {
    value(newValue) {
      var picker = $(this.$el).data('DateTimePicker')
      if (picker) {
        //console.log(newValue);
        picker.date(newValue || null)
      }
    },
    minDate(newValue) {
      var picker = $(this.$el).data('DateTimePicker')
      if (picker) {
        picker.minDate(newValue || false)
      }
    },
    maxDate(newValue) {
      var picker = $(this.$el).data('DateTimePicker')
      if (picker) {
        picker.maxDate(newValue || false)
      }
    }
  },
  mounted() {
    var $el = $(this.$el)
    //console.log(this.minDate);
    //console.log(this.maxDate);
    $el.datetimepicker({
      useCurrent: false,
      format: this.format,
      minDate: this.minDate ? this.minDate : false,
      maxDate: this.maxDate ? this.maxDate : false,
      locale: this.locale,
    })
    var self = this
    $el.on('dp.change', event => {
      self.$emit('input', $el.children('input').val())
    })
  }
}
</script>