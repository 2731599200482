<template>
  <div>
    <nav class="left-sidebar shadow">
      <nav class="navbar navbar-expand navbar-dark bg-dark-green">
        <span class="navbar-brand d-none d-md-block"><i class="fas fa-mail-bulk fa-lg"></i> {{ app }}</span>
        <span class="navbar-brand d-md-none"><i class="fas fa-mail-bulk fa-lg"></i></span>
        <a href="#" class="nav-close left-sidebar-toggler d-md-none ml-auto"><i class="fas fa-times fa-lg"></i></a>
      </nav>
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'correspondencia' }" v-if="$_.includes($auth.permisos, 'ver_correspondencia')">
            <i class="fas fa-inbox fa-lg"></i>
            Correspondencia
          </router-link>
        </li>

        <li class="nav-item" v-if="$_.some(['reportesib'], (p) => $_.includes($auth.permisos, p))">
          <a class="nav-link dropdown-toggle" data-toggle="collapse" href="#collapse-reportes" role="button" aria-expanded="false" aria-controls="collapse-reportes">
            <i class="fas fa-tools fa-lg"></i>
            Reportes
          </a>
          <div class="collapse" id="collapse-reportes">
            <ul class="nav flex-column">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'rpt01Importaciones' }" v-if="$_.includes($auth.permisos, 'rpt_impo')">
                  <i class="fas fa-file-alt fa-lg"></i>
                  <span>Importaciones</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'rpt02Exportaciones' }" v-if="$_.includes($auth.permisos, 'rpt_expo')">
                  <i class="fas fa-file-alt fa-lg"></i>
                  <span>Exportaciones</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'rpt03DTA' }" v-if="$_.includes($auth.permisos, 'rpt_impo')">
                  <i class="fas fa-file-alt fa-lg"></i>
                  <span>DTA</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'rpt04AuditoriaDI' }" v-if="$_.includes($auth.permisos, 'rpt_impo')">
                  <i class="fas fa-file-alt fa-lg"></i>
                  <span>Auditoria DI</span>
                </router-link>
               </li>
              <li class="nav-item">
                 <router-link class="nav-link" :to="{ name: 'rpt05Cotecmar' }" v-if="$_.includes($auth.permisos, 'rpt_impo')">
                  <i class="fas fa-file-alt fa-lg"></i>
                  <span>Cotecmar</span>
                </router-link>
              </li>
              <li class="nav-item">
                 <router-link class="nav-link" :to="{ name: 'rpt06Temporales' }" v-if="$_.includes($auth.permisos, 'rpt_impo')">
                  <i class="fas fa-file-alt fa-lg"></i>
                  <span>Temporales</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'rpt08VerificaCapital' }" v-if="$_.includes($auth.permisos, 'rpt_impo')">
                  <i class="fas fa-file-alt fa-lg"></i>
                  <span>Verificacion Capital Clientes</span>
                </router-link>
              </li>
              <li class="nav-item">
                 <router-link class="nav-link" :to="{ name: 'rpt09Pendientes' }" v-if="$_.includes($auth.permisos, 'rpt_impo')">
                  <i class="fas fa-file-alt fa-lg"></i>
                  <span>Pendientes Facturacion</span>
                </router-link>
              </li>
              <li class="nav-item">
                 <router-link class="nav-link" :to="{ name: 'rpt10Archivo' }" v-if="$_.includes($auth.permisos, 'rpt_impo')">
                  <i class="fas fa-file-alt fa-lg"></i>
                  <span>DO Archivadores</span>
                </router-link>
              </li>
              <li class="nav-item">
                 <router-link class="nav-link" :to="{ name: 'rpt11Archivador' }" v-if="$_.includes($auth.permisos, 'xxx')">
                  <i class="fas fa-file-alt fa-lg"></i>
                  <span>xxx</span>
                </router-link>
              </li>
              <li class="nav-item">
                 <router-link class="nav-link" :to="{ name: 'rpt07Gerencial' }" v-if="$_.includes($auth.permisos, 'rpt_gerencial')">
                  <i class="fas fa-file-alt fa-lg"></i>
                  <span>Gerencial</span>
                </router-link>
              </li>
              
             
            </ul>
          </div>
        </li>        
        <li class="nav-item" v-if="$_.some(['ver_tipos_correspondencia'], (p) => $_.includes($auth.permisos, p))">
          <a class="nav-link dropdown-toggle" data-toggle="collapse" href="#collapse-administracion" role="button" aria-expanded="false" aria-controls="collapse-administracion">
            <i class="fas fa-tools fa-lg"></i>
            Administracion
          </a>
          <div class="collapse" id="collapse-administracion">
            <ul class="nav flex-column">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'tipos-correspondencia' }" v-if="$_.includes($auth.permisos, 'ver_tipos_correspondencia')">
                  <i class="fas fa-file-alt fa-lg"></i>
                  <span>Tipos de correspondencia</span>
                </router-link>
              </li>
               
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'oficinas' }" v-if="$_.includes($auth.permisos, 'ver_oficinas')">
                  <i class="fas fa-building fa-lg"></i>
                  <span>Oficinas</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'metas-oficina' }" v-if="$_.includes($auth.permisos, 'ver_oficinas')">
                  <i class="fas fa-building fa-lg"></i>
                  <span>Metas Oficinas</span>
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link dropdown-toggle" data-toggle="collapse" href="#collapse-seguridad" role="button" aria-expanded="false" aria-controls="collapse-seguridad">
            <i class="fas fa-lock fa-lg"></i>
            Seguridad
          </a>
          <div class="collapse" id="collapse-seguridad">
            <ul class="nav flex-column">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'usuarios' }" v-if="$_.includes($auth.permisos, 'ver_usuarios')">
                  <i class="fas fa-users fa-lg"></i>
                  <span>Usuarios</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'roles' }" v-if="$_.includes($auth.permisos, 'ver_roles')">
                  <i class="fas fa-user-shield fa-lg"></i>
                  <span>Roles</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'cambiar-password' }">
                  <i class="fas fa-key fa-lg"></i>
                  <span>Cambio de password</span>
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <!--
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'exportaciones' }">
            <i class="fas fa-insert-drive-file fa-lg"></i>
            <span>Exportaciones</span>
          </router-link>
        </li>
        -->
      </ul>
    </nav>
    <div class="outer-wrapper">
      <nav class="navbar navbar-expand-md navbar-dark bg-green sticky-top">
        <button class="navbar-toggler left-sidebar-toggler mr-1" type="button">
          <span class="navbar-toggler-icon"></span>
        </button>
        <span class="navbar-brand d-md-none">{{ app }}</span>
        <div class="ml-auto">
          <form class="form-inline my-0">
            <span class="navbar-text">{{ nombre }}</span>
            <button class="btn btn-outline-light ml-1" type="button" @click.prevent="logout"><i class="fas fa-door-open fa-lg"></i></button>
          </form>
        </div>
      </nav>
      <div class="inner-wrapper">
        <div class="container-fluid">
          <img :src="logo" alt="logo"/>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { url, dialogs } from '../../services'
export default {
  data() {
    return {
      logo: url.create('/image'),
      app: 'Correspondencia'
    }
  },
  computed: {
    nombre() {
      return this.$auth.me.nombre
    }
  },
  methods: {
    async logout() {
      await this.$auth.unAuthenticate()
      this.$router.push({ name: 'login' })
    }
  },
  mounted() {
    $('.left-sidebar-toggler').on('click', function(event) {
      event.preventDefault()
      $('.left-sidebar').toggleClass('open')
    })
  }
}
</script>

<style>
</style>