<template>
  <div class="mt-2">
    <h3>Usuarios <small v-if="$_.includes($auth.permisos, 'agregar_usuarios')"><a href="" @click.prevent="agregar" title="Agregar">[Agregar]</a></small></h3>
    <div class="row mb-2">
      <form class="col-md-12">
        <button type="button" class="btn btn-success" @click="descargarXlsx">Exportar a xlsx</button>
      </form>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Nombre de usuario</th>
                <th>Email</th>
                <th>Rol</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="usuario in usuarios" :key="usuario.id">
                <td>{{ usuario.nombre }}</td>
                <td>{{ usuario.username }}</td>
                <td>{{ usuario.email }}</td>
                <td>{{ usuario.rol ? usuario.rol.nombre : 'ND' }}</td>
                <td>
                  <a href="" @click.prevent="editar(usuario)" title="Editar" v-if="$_.includes($auth.permisos, 'editar_usuarios')"><i class="fas fa-edit fa-lg"></i></a>
                  <a href="" @click.prevent="eliminar(usuario)" title="Eliminar" v-if="$_.includes($auth.permisos, 'eliminar_usuarios')"><i class="fas fa-trash fa-lg"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="cambiarPagina"></pagination>
      </div>
    </div>
    <form @submit.prevent="guardar">
      <modal v-model="modal">
        <div slot="header" class="modal-header">
          <h4 class="modal-title">Usuarios</h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label class="form-control-label" for="usuario-nombre">Nombre:</label>
            <input type="text" class="form-control" id="usuario-nombre" placeholder="Nombre" v-model="usuario.nombre">
          </div>
          <div class="form-group">
            <label class="form-control-label" for="usuario-email">Email:</label>
            <input type="text" class="form-control" id="usuario-email" placeholder="Email" v-model="usuario.email">
          </div>
          <div class="form-group">
            <label class="form-control-label" for="usuario-username">Nombre de usuario:</label>
            <input type="text" class="form-control" id="usuario-username" placeholder="Nombre de usuario" v-model="usuario.username">
          </div>
          <div class="form-group">
            <label class="form-control-label" for="usuario-password">Password:</label>
            <input type="text" class="form-control" id="usuario-password" placeholder="Password" v-model="usuario.password">
          </div>
          <div class="form-group">
            <label class="form-control-label" for="usuario-oficina">Oficina principal:</label>
            <select class="form-control" id="usuario-oficina" placeholder="Oficina principal" v-model="usuario.oficina_id">
              <option :value="null">Seleccione uno</option>
              <option v-for="oficina in oficinas" :key="oficina.id" :value="oficina.id">{{ oficina.nombre }}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="form-control-label" for="usuario-oficinas">Otras oficinas:</label>
            <select class="form-control" id="usuario-oficinas" placeholder="Otras oficinas" v-model="usuario.oficinas" multiple>
              <option v-for="oficina in oficinas" :key="oficina.id" :value="oficina.id">{{ oficina.nombre }}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="form-control-label" for="usuario-rol">Rol:</label>
            <select class="form-control" id="usuario-rol" placeholder="Rol" v-model="usuario.rol_id">
              <option :value="null">Seleccione uno</option>
              <option v-for="rol in roles" :key="rol.id" :value="rol.id">{{ rol.nombre }}</option>
            </select>
          </div>
        </div>
        <div slot="footer" class="modal-footer">
          <button type="submit" class="btn btn-success">Guardar</button>
          <button type="button" class="btn btn-secondary" @click="modal = false">Salir</button>
        </div>
      </modal>
    </form>
  </div>
</template>

<script>
import { url, dialogs } from '../../services'

export default {
  data() {
    return {
      modal: false,
      oficinas: [],
      roles: [],
      usuarios: [],
      usuario: {
        oficinas: []
      },
      id: null,
      pagination: {
        currentPage: 1,
        pages: 1
      }
    }
  },
  methods: {
    agregar() {
      this.id = null
      this.usuario = {
        oficina_id: null,
        rol_id: null,
        oficinas: []
      }
      this.modal = true
    },
    editar(usuario) {
      this.id = usuario.id
      this.usuario = {
        nombre: usuario.nombre,
        apellido: usuario.apellido,
        email: usuario.email,
        username: usuario.username,
        oficina_id: usuario.oficina_id,
        rol_id: usuario.rol_id,
        oficinas: _.map(usuario.oficinas, function(oficina) {
          return oficina.id
        }),
      }
      this.modal = true
    },
    async eliminar(usuario) {
      let res = await dialogs.confirm(
        `Esta seguro de que desea borrar el usuario ${usuario.nombre} ${usuario.apellido}`
      )
      if (res) {
        try {
          await axios.delete(url.create(`/usuarios/${usuario.id}`))
          PNotify.notice('Usuario eliminado correctamente')
          await this.listar()
        } catch (ex) {}
      }
    },
    async guardar() {
      try {
        let response = this.id
          ? await axios.put(url.create(`/usuarios/${this.id}`), this.usuario)
          : await axios.post(url.create('/usuarios'), this.usuario)
        PNotify.notice('Usuario guardado correctamente')
        await this.listar()
        this.modal = false
      } catch (ex) {}
    },
    async listar() {
      try {
        let response = await axios.get(url.create('/usuarios'), {
          params: { page: this.pagination.currentPage }
        })
        this.usuarios = response.data.data
        this.pagination.pages = Math.ceil(
          response.data.total / response.data.per_page
        )
      } catch (ex) {}
    },
    cambiarPagina(page) {
      this.currentPage = page
      this.listar()
    },
    async descargarXlsx() {
      let response = await axios.get(url.create('/usuarios/excel'), {
        params: {}
      })
      window.location.href = response.data.enlace
    },
    async listarOficinas() {
      try {
        let response = await axios.get(url.create('/oficinas'))
        this.oficinas = response.data
      } catch (ex) {}
    },
    async listarRoles() {
      try {
        let response = await axios.get(url.create('/roles'))
        this.roles = response.data
      } catch (ex) {}
    },
  },
  mounted() {
    this.listar()
    this.listarOficinas()
    this.listarRoles()
  }
}
</script>
