import Url from './url'

const TOKEN_EXPIRATION_KEY = '$token-expiration'
const $storage = window.localStorage
const url = new Url()

class AuthService {
  constructor() {
    this._me = null
    this._filled = false
  }
  verify() {
    let expires = parseInt($storage.getItem(TOKEN_EXPIRATION_KEY) || '0')
    return expires > Date.now()
  }
  async refresh() {
    try {
      const response = await axios.post(url.create('/auth/refresh'))
      $storage.setItem(TOKEN_EXPIRATION_KEY, (response.data.expires_in * 1000).toString())
      return true
    } catch (error) {
      $storage.removeItem(TOKEN_EXPIRATION_KEY)
      return false
    }
  }
  async authenticate(credenciales) {
    try {
      const response = await axios.post(url.create('/auth/login'), credenciales)
      $storage.setItem(TOKEN_EXPIRATION_KEY, (response.data.expires_in * 1000).toString())
      return true
    } catch (error) {
      return false
    }
  }
  async data() {
    if (!this._filled) {
      try {
        this._me = (await axios.get(url.create('/auth/me'))).data
        this._filled = true
      } catch (error) {
        this._filled = false
      }
    }
  }
  get me() {
    return this._me
  }
  get permisos() {
    return _.get(this._me, 'rol.permisos') || []
  }
  async unAuthenticate() {
    $storage.removeItem(TOKEN_EXPIRATION_KEY)
    await axios.post(url.create('/auth/logout'))
    this._me = null
    this._filled = false
  }
}

export default AuthService
