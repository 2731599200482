<template>
<div class="input-group file" :class="'input-group-' + inputSize">
    <input type="text" class="form-control" :id="id" :placeholder="placeholder" :value="value" readonly>
    <label class="input-group-append">
        <span class="input-group-text"><i class="fas fa-upload"></i></span>
        <input ref="upload" type="file">
    </label>
</div>
</template>

<script>
export default {
  name: 'Upload',
  props: {
    value: {
      type: String
    },
    id: {
      type: String
    },
    inputSize: {
      type: String
    },
    placeholder: {
      type: String
    },
    //path: {
    //    type: String,
    //    default: '/private/comun/upload'
    //},
    name: {
      type: String,
      default: 'file'
    }
  },
  mounted() {
    let self = this
    $(this.$el)
      .find('input[type="file"]')
      .on('change', function(event) {
        let files = event.target.files || event.dataTransfer.files
        if (files.length) {
          let value = files[0].name
          self.$emit('change', files[0])
          self.$emit('input', value)
          $(event.target).replaceWith(
            $(event.target)
              .val('')
              .clone(true)
          )
        }
      })
  }
}
</script>

<style scoped>
.input-group.file label, .input-group.file .input-group-addon {
  font-size: 15px;
  margin: 0;
  cursor: pointer;
}

.input-group.file input[type="text"][readonly] {
  background-color: #ffffff;
}

.input-group.file input[type="file"] {
  display: none;
}
</style>