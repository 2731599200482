import { auth } from './services'

export default async function(response) {
    if(response.status === 401) {
        try {
            await auth.refresh()
            return axios(response.config)
        } catch(error) {
            return Promise.reject(error)//No estoy totalmente seguro de esto
        }
    }
    return response
}