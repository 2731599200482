import Vue from 'vue';

Vue.directive('searchable', {
    inserted(el, bindings) {

        let defaultProps = {
            liveSearch: true,
            liveSearchStyle: 'contains',
            noneSelectedText: 'Ninguno seleccionado',
            size: 10
        }

        let options = {};
        options = _.extend({}, defaultProps, bindings.value || {});
                
        $(el).selectpicker(options);
    },
    componentUpdated(el) {
        $(el).selectpicker('refresh');
    }
});