<template>
  <div class="mt-2">
    <h3>Tipos <small><a href="" @click.prevent="agregar" title="Agregar">[Agregar]</a></small></h3>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Descripción</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="tipo in tipos" :key="tipo.id">
                <td>{{ tipo.nombre }}</td>
                <td>{{ tipo.descripcion }}</td>
                <td>
                  <a href="" @click.prevent="editar(tipo)" title="Editar"><i class="fas fa-edit fa-lg"></i></a>
                  <a href="" @click.prevent="eliminar(tipo)" title="Eliminar"><i class="fas fa-trash fa-lg"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="cambiarPagina"></pagination>
      </div>
    </div>
    <form @submit.prevent="guardar">
      <modal v-model="modal">
        <div slot="header" class="modal-header">
          <h4 class="modal-title">Tipos</h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label class="form-control-label" for="tipo-nombre">Nombre:</label>
            <input type="text" class="form-control" id="tipo-nombre" placeholder="Nombre" v-model="tipo.nombre">
          </div>
          <div class="form-group">
            <label class="form-control-label" for="tipo-descripcion">Descripción:</label>
            <input type="text" class="form-control" id="tipo-descripcion" placeholder="Descripción" v-model="tipo.descripcion">
          </div>
          <div class="form-group">
            <label class="form-control-label" for="rol-permisos">Campos:</label>
            <template v-for="(campo, id) in campos">
              <div class="form-check" :key="id">
                <label>
                  <input type="checkbox" :value="id" v-model="tipo.campos">
                  <strong>{{ campo }}</strong>
                </label>
              </div>
            </template>
          </div>
        </div>
        <div slot="footer" class="modal-footer">
          <button type="submit" class="btn btn-success">Guardar</button>
          <button type="button" class="btn btn-secondary" @click="modal = false">Salir</button>
        </div>
      </modal>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

import { url, dialogs } from '../../services'

export default {
  data() {
    return {
      modal: false,
      tipos: [],
      campos: [],
      tipo: {
        campos: []
      },
      id: null,
      pagination: {
        currentPage: 1,
        pages: 1
      }
    }
  },
  methods: {
    agregar() {
      this.id = null
      this.tipo = {
        campos: []
      }
      this.modal = true
    },
    editar(tipo) {
      this.id = tipo.id
      this.tipo = {
        nombre: tipo.nombre,
        descripcion: tipo.descripcion,
        campos: tipo.campos
      }
      this.modal = true
    },
    async eliminar(tipo) {
      let res = await dialogs.confirm(
        `Esta seguro de que desea borrar el tipo ${tipo.nombre}`
      )
      if (res) {
        try {
          await axios.delete(url.create(`/tipos-correspondencia/${tipo.id}`))
          PNotify.notice('Tipo eliminado correctamente')
          await this.listar()
        } catch (ex) {}
      }
    },
    async guardar() {
      try {
        let response = this.id
          ? await axios.put(url.create(`/tipos-correspondencia/${this.id}`), this.tipo)
          : await axios.post(url.create('/tipos-correspondencia'), this.tipo)
        PNotify.notice('Tipo guardado correctamente')
        await this.listar()
        this.modal = false
      } catch (ex) {}
    },
    async listar() {
      try {
        let response = await axios.get(url.create('/tipos-correspondencia'), {
          params: { page: this.pagination.currentPage }
        })
        this.tipos = response.data.data
        this.pagination.pages = Math.ceil(
          response.data.total / response.data.per_page
        )
      } catch (ex) {}
    },
    async listarCampos() {
      try {
        let response = await axios.get(url.create('/tipos-correspondencia/campos'))
        this.campos = response.data
      } catch (ex) {}
    },
    cambiarPagina(page) {
      this.currentPage = page
      this.listar()
    },
  },
  mounted() {
    this.listar()
    this.listarCampos()
  }
}
</script>
