<template>
    <section>
        <div class="row mb-2">
            <div class="col-sm-12">
                <form>
                    <div>
                        <legend>ARCHIVADORES</legend>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <button type="button" class="btn btn-success" @click="descargarXlsx">Exportar a xlsx</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'

import { url, dialogs } from '../../services'

export default {
    data() {
        return {
            errores: {                
            },
            filtro: {
                
            }
        }
    },
    methods: {
        async descargarXlsx() {
            
            try{              

                let response = await axios.get(url.create('/rpt11Archivador/excel'), { params: { filtro: this.filtro } });
                window.location.href = response.data.enlace;
            }
            catch(ex){
                alert(ex.response.data);
            }
        }
    },
}
</script>
