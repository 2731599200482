import './bootstrap'

import Vue from 'vue'
import VueRouter from 'vue-router'

import { auth } from './services'
import interceptor from './interceptor'
import routes from './routes'
import './components'
import './directives'

axios.interceptors.response.use(interceptor)

Vue.use(VueRouter)
Vue.use({
    install(Vue) {
        Vue.prototype.$_ = _
        Vue.prototype.$$ = $
        Vue.prototype.$auth = auth
    }
})

const router = new VueRouter({
    routes
})

router.beforeEach(async function (to, from, next) {
    if (to.matched.some(record => record.meta.secured)) {
        if (auth.verify() || await auth.refresh()) {
            try {
                await auth.data()
                next()
            } catch (error) {
                next({ name: 'login' })
            }
        } else {
            next({ name: 'login' })
        }
    } else {
        next()
    }
})

export default new Vue({
    el: '#app',
    router
})
