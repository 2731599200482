import Home from '../controllers/home'
import Login from '../controllers/login'
export default [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    }
]