<template>
  <div class="mt-2">
    <h3>Correspondencia <small v-if="$_.includes($auth.permisos, 'agregar_correspondencia')"><a href="" @click.prevent="agregar" title="Agregar">[Agregar]</a></small></h3>
    <div class="row mb-2">
      <div class="col-md-12">
        <form @submit.prevent="listar">
          <div class="form-group row" v-if="$_.includes($auth.permisos, 'ver_todo')">
            <label for="filtro-destinatario" class="col-sm-2 col-form-label">Destinatario:</label>
            <div class="col-sm-4">
              <select class="form-control" id="filtro-destinatario" placeholder="Destinatario" v-model="filtro.destinatario_id">
                <option :value="null">Seleccione uno</option>
                <option v-for="destinatario in destinatarios" :key="destinatario.id" :value="destinatario.id">{{ destinatario.nombre }}</option>
              </select>
            </div>
          </div>
          <div class="form-group row" v-if="oficinas.length > 1">
            <label for="filtro-oficina" class="col-sm-2 col-form-label">Oficina</label>
            <div class="col-sm-4">
              <select class="form-control" id="filtro-oficina" placeholder="Oficina" v-model="filtro.oficina_id">
                <option :value="null">Seleccione uno</option>
                <option v-for="oficina in oficinas" :key="oficina.id" :value="oficina.id">{{ oficina.nombre }}</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="filtro-remitente" class="col-sm-2 col-form-label">Remitente:</label>
            <div class="col-sm-4">
              <input type="text" class="form-control" id="filtro-remitente" placeholder="Remitente" v-model="filtro.remitente">
            </div>
            <label for="filtro-razon-social" class="col-sm-2 col-form-label">Razón Social:</label>
            <div class="col-sm-4">
              <input type="text" class="form-control" id="filtro-razon-social" placeholder="Razón Social" v-model="filtro.razon_social">
            </div>
          </div>
          <div class="form-group row">
            <label for="filtro-fecha" class="col-sm-2 col-form-label">Fecha</label>
            <div class="col-sm-4 row no-gutters">
              <div class="col-sm-6">
                <datetimepicker placeholder="Desde" v-model="filtro.fecha_desde"></datetimepicker>
              </div>
              <div class="col-sm-6">
                <datetimepicker placeholder="Hasta" v-model="filtro.fecha_hasta"></datetimepicker>
              </div>
            </div>
            <label for="filtro-do" class="col-sm-2 col-form-label">DO:</label>
            <div class="col-sm-4">
              <input type="text" class="form-control" id="filtro-do" placeholder="DO" v-model="filtro.do">
            </div>
          </div>
          <div class="form-group row">
            <label for="filtro-no-requerimiento" class="col-sm-2 col-form-label">No. de requerimiento:</label>
            <div class="col-sm-4">
              <input type="text" class="form-control" id="filtro-no-requerimiento" placeholder="No. de requerimiento" v-model="filtro.no_requerimiento">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-12">
              <button type="submit" class="btn btn-primary">Filtrar</button>
              <button type="button" class="btn btn-success" @click="descargarXlsx">Exportar a xlsx</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Fecha y hora de ingreso</th>
                <th>Consecutivo</th>
                <th>Oficina</th>
                <th>Tipo</th>
                <th>Courier</th>
                <th>Número de guía</th>
                <th>Remitente</th>
                <th>Destinatario</th>
                <th>Estado</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="correspondencia in correspondencia" :key="correspondencia.id">
                <td>{{ correspondencia.fecha_ingreso }}</td>
                <td>{{ correspondencia.consecutivo }}</td>
                <td>{{ correspondencia.oficina ? correspondencia.oficina.nombre : 'ND' }}</td>
                <td>{{ correspondencia.tipo_correspondencia ? correspondencia.tipo_correspondencia.nombre : 'ND' }}</td>
                <td>{{ correspondencia.courier }}</td>
                <td>{{ correspondencia.numero_guia }}</td>
                <td>{{ correspondencia.remitente }}</td>
                <td>{{ correspondencia.destinatario ? correspondencia.destinatario.nombre : 'ND' }}</td>
                <td>{{ correspondencia.nombre_estado }}</td>
                <td>
                  <a href="" @click.prevent="gestionarDocumento(correspondencia)" title="Gestionar documentos" v-if="$_.includes($auth.permisos, 'documentos_correspondencia')"><i class="fas fa-file-import fa-lg"></i></a>
                  <a href="" @click.prevent="notificar(correspondencia)" title="Notificar" v-if="correspondencia.estado === 'SIN_NOTIFICAR' && $_.includes($auth.permisos, 'notificar_correspondencia')"><i class="fas fa-envelope fa-lg"></i></a>
                  <a href="" @click.prevent="confirmar(correspondencia)" title="Confirmar recepción" v-if="correspondencia.estado === 'NOTIFICADO' && $_.includes($auth.permisos, 'confirmar_correspondencia')"><i class="fas fa-check-circle fa-lg"></i></a>
                </td>
                <td>
                  <a href="" @click.prevent="editar(correspondencia)" title="Editar" v-if="$_.includes($auth.permisos, 'editar_correspondencia')"><i class="fas fa-edit fa-lg"></i></a>
                  <a href="" @click.prevent="eliminar(correspondencia)" title="Eliminar" v-if="$_.includes($auth.permisos, 'eliminar_correspondencia')"><i class="fas fa-trash fa-lg"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination v-model="pagination.currentPage" :pages="pagination.pages" @change="cambiarPagina"></pagination>
      </div>
    </div>
    <form @submit.prevent="guardar">
      <modal v-model="modal">
        <div slot="header" class="modal-header">
          <h4 class="modal-title">Correspondencia</h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label class="form-control-label" for="correspondencia-tipo">Tipo:</label>
            <select class="form-control" id="correspondencia-tipo" placeholder="Tipo" v-model="$data._correspondencia.tipo_correspondencia_id">
              <option :value="null">Seleccione uno</option>
              <option v-for="tipo in tipos" :key="tipo.id" :value="tipo.id">{{ tipo.nombre }}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="form-control-label" for="correspondencia-courier">Courier:</label>
            <input type="text" class="form-control" id="correspondencia-courier" placeholder="Courier" v-model="$data._correspondencia.courier">
          </div>
          <div class="form-group">
            <label class="form-control-label" for="correspondencia-numero-guia">Número de guía:</label>
            <input type="text" class="form-control" id="correspondencia-numero-guia" placeholder="Número de guía" v-model="$data._correspondencia.numero_guia">
          </div>
          <div class="form-group">
            <label class="form-control-label" for="correspondencia-remitente">Remitente:</label>
            <input type="text" class="form-control" id="correspondencia-remitente" placeholder="Remitente" v-model="$data._correspondencia.remitente">
          </div>
          <div class="form-group">
            <label class="form-control-label" for="correspondencia-cliente">Cliente:</label>
            <input type="text" class="form-control" id="correspondencia-cliente" placeholder="Cliente" v-model="$data._correspondencia.cliente">
          </div>
          <div class="form-group">
            <label class="form-control-label" for="correspondencia-destinatario">Destinatario:</label>
            <select class="form-control" id="correspondencia-destinatario" placeholder="Destinatario" v-model="$data._correspondencia.destinatario_id">
              <option :value="null">Seleccione uno</option>
              <option v-for="destinatario in destinatarios" :key="destinatario.id" :value="destinatario.id">{{ destinatario.nombre }}</option>
            </select>
          </div>          
          <div class="form-group" v-if="$_.includes($auth.permisos, 'ingresar_otras')">
            <label class="form-control-label" for="usuario-oficina">Oficina:</label>
            <select class="form-control" id="usuario-oficina" placeholder="Oficina" v-model="$data._correspondencia.oficina_id">
              <option :value="null">Seleccione uno</option>
              <option v-for="oficina in oficinas" :key="oficina.id" :value="oficina.id">{{ oficina.nombre }}</option>
            </select>
          </div>
        </div>
        <div slot="footer" class="modal-footer">
          <button type="submit" class="btn btn-success">Guardar</button>
          <button type="button" class="btn btn-secondary" @click="modal = false">Salir</button>
        </div>
      </modal>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

import { url, dialogs } from '../../services'

export default {
  data() {
    return {
      modal: false,
      destinatarios: [],
      tipos: [],
      oficinas: [],
      correspondencia: [],
      _correspondencia: {},
      id: null,
      pagination: {
        currentPage: 1,
        pages: 1
      },
      filtro: {}
    }
  },
  methods: {
    agregar() {
      this.id = null
      this.$data._correspondencia = {
        tipo_correspondencia_id: null,
        destinatario_id: null,
        oficina_id: null
      }
      this.modal = true
    },
    editar(correspondencia) {
      this.id = correspondencia.id
      this.$data._correspondencia = {
        tipo_correspondencia_id: correspondencia.tipo_correspondencia_id,
        courier: correspondencia.courier,
        numero_guia: correspondencia.numero_guia,
        remitente: correspondencia.remitente,
        cliente:correspondencia.cliente,
        destinatario_id: correspondencia.destinatario_id,        
        oficina_id: correspondencia.oficina_id
      }
      this.modal = true
    },
    async eliminar(correspondencia) {
      let res = await dialogs.confirm(
        `Esta seguro de que desea borrar la correspondencia Noº ${correspondencia.numero_guia}`
      )
      if (res) {
        try {
          await axios.delete(
            url.create(`/correspondencia/${correspondencia.id}`)
          )
          PNotify.notice('Correspondencia eliminada correctamente')
          await this.listar()
        } catch (ex) {}
      }
    },
    async notificar(correspondencia) {
      if (correspondencia.destinatario) {
        let res = await dialogs.confirm(
          `Esta seguro de que desea enviar una notificación a ${correspondencia.destinatario.nombre}`
        )
        if (res) {
          try {
            await axios.post(
              url.create(`/correspondencia/${correspondencia.id}/notificacion`)
            )
            PNotify.notice('Notificación enviada correctamente')
            await this.listar()
          } catch (ex) {}
        }
      }
    },
    async confirmar(correspondencia) {
      if (correspondencia.destinatario) {
        let res = await dialogs.confirm(
          `Confirmar la recepción de la correspondencia ${correspondencia.consecutivo}`
        )
        if (res) {
          try {
            await axios.post(
              url.create(`/correspondencia/${correspondencia.id}/confirmacion`)
            )
            PNotify.notice('Confirmación registrada correctamente')
            await this.listar()
          } catch (ex) {}
        }
      }
    },
    async gestionarDocumento(correspondencia) {
      this.$router.push({
        name: 'documentos',
        params: { id: correspondencia.id }
      })
    },
    async guardar() {
      try {
        let response = this.id
          ? await axios.put(
              url.create(`/correspondencia/${this.id}`),
              this.$data._correspondencia
            )
          : await axios.post(
              url.create('/correspondencia'),
              this.$data._correspondencia
            )
        PNotify.notice('Correspondencia guardado correctamente')
        await this.listar()
        this.modal = false
      } catch (ex) {}
    },
    async listar() {
      try {
        let response = await axios.get(url.create('/correspondencia'), {
          params: { page: this.pagination.currentPage, filtro: this.filtro }
        })
        this.correspondencia = response.data.data
        this.pagination.pages = Math.ceil(
          response.data.total / response.data.per_page
        )
      } catch (ex) {}
    },
    cambiarPagina(page) {
      this.currentPage = page
      this.listar()
    },
    async listarDestinatarios() {
      try {
        let response = await axios.get(url.create('/usuarios'), {
          params: { asociados: true }
        })
        this.destinatarios = response.data
      } catch (ex) {}
    },
    async listarTipos() {
      try {
        let response = await axios.get(url.create('/tipos-correspondencia'))
        this.tipos = response.data
      } catch (ex) {}
    },
    async listarOficinas() {
      try {
        let response = await axios.get(url.create('/oficinas'), {
          params: { asociadas: true }
        })
        this.oficinas = response.data
      } catch (ex) {}
    },
    async descargarXlsx() {
      let response = await axios.get(url.create('/correspondencia/excel'), {
        params: { filtro: this.filtro }
      })
      window.location.href = response.data.enlace
    }
  },
  mounted() {
    this.listarDestinatarios()
    this.listarTipos()
    this.listarOficinas()
    this.listar()
  }
}
</script>
