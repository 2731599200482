<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3 px-2">
        <div class="login-panel card">
          <!--<img src="https://picsum.photos/600/300" class="card-img-top" alt="Logo" />-->
          <img src="img/logo.png" class="card-img-top" alt="Logo" />
          <div class="card-body">
            <form role="form" @submit.prevent="login">
              <fieldset>
                <div class="form-group">
                  <div class="input-group">
                    <span class="input-group-addon">
                      <i class="glyphicon glyphicon-user"></i>
                    </span>
                    <input class="form-control" placeholder="Usuario" v-model="credenciales.username" type="text" autofocus autocomplete="off" />
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <span class="input-group-addon">
                      <i class="glyphicon glyphicon-lock"></i>
                    </span>
                    <input class="form-control" placeholder="Password" v-model="credenciales.password" type="password" value />
                  </div>
                </div>
                <button type="submit" class="btn btn-success btn-block">Ingresar</button>
              </fieldset>
            </form>
            
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      credenciales: {}
    }
  },
  methods: {
    async login() {
      try {
        await this.$auth.authenticate(this.credenciales)
        this.$router.push({ name: 'dashboard' })
      } catch (error) {
        console.log(error)
      }
    }
  },
  created() {
    this.credenciales = {}
    if (this.$auth.verify()) {
      this.$router.push({ name: 'dashboard' })
    }
  }
}
</script>

<style>
</style>