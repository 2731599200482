<template>
  <div class="mt-2">
    <div class="row mb-2">
      <div class="col-md-6 offset-md-3 px-2">
        <form @submit.prevent="guardar">
          <div class="form-group row">
            <label for="archivo" class="col-sm-4 col-form-label">Archivo</label>
            <div class="col-sm-8">
              <upload id="archivo" placeholder="Archivo" @change="cargar" v-model="filename"></upload>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-12">
              <button type="submit" class="btn btn-success" :disabled="disabled">Guardar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import { url } from '@/services'
import { dialogs } from '../../services'

export default {
  data() {
    return {
      archivo: null,
      filename: null,
      disabled: false
    }
  },
  methods: {
    async cargar(archivo) {
      this.archivo = archivo
    },
    async guardar() {
      try {
        this.disabled = true
        let data = new FormData()
        data.append('archivo', this.archivo, this.filename)
        let response = await axios.post(
          url.create('/rpt04AuditoriaDI/excel'),
          data,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        this.filename = ''
        //this.$router.push({ name: 'start' })
        window.location.href = response.data.enlace
      } catch (error) {
        PNotify.error('Ocurrió un error')
      }
    }
  }
}
</script>
