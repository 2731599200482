class UrlService {
  base() {
    return apiUrl;
  }
  create(path) {
    return `${apiUrl}${path}`;
  }
}

export default UrlService;
