<template>
    <nav aria-label="Page navigation">
        <ul class="pagination" :class="paginationClass">
            <li class="page-item">
                <a href="" aria-label="Previous" @click.prevent="previous()" class="page-link">
                    <span aria-hidden="true">&#xAB;</span>
                </a>
            </li>
            <li v-for="page in pageRange" :key="page" :class="{ active: page === value }" class="page-item">
                <a href="" @click.prevent="changePage(page)" class="page-link">{{ page }}</a>
            </li>
            <li class="page-item">
                <a href="" aria-label="Next" @click.prevent="next()" class="page-link">
                    <span aria-hidden="true">&#xBB;</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'PaginationComponent',
    props: {
        value: {
            type: Number,
            default: 1
        },
        pages: {
            type: Number,
            default: 0
        },
        maxPages: {
            type: Number,
            default: 10
        },
        size: {
            type: String,
            default: ''
        }
    },
    methods: {
        changePage(number) {
            if(this.value != number) {
                this.$emit('input', number);
                this.$emit('change', number);
            }
        },
        previous() {
            if(this.value - 1 > 0) {
                this.changePage(this.value - 1);
            }
        },
        next() {
            if(this.value + 1 <= this.pages) {
                this.changePage(this.value + 1);
            }
        }
    },
    computed: {
        paginationClass() {
            switch(this.size) {
                case 'large': return 'pagination-lg';
                case 'small': return 'pagination-sm';
            }
        },
        pageRange() {
            let last = this.pages,
            current = this.value,
            delta = this.maxPages / 2 - 2,
            pages = [];
            
            pages.push(1);

            if(last > 1) {
                let left = 2,
                right = last;

                if(last > 3 + (delta * 2)) {
                    left = current - delta;
                    right = current + delta + 1;
                    
                    if(left < delta) {
                        left = 2;
                        right = 3 + (delta * 2);
                    }

                    if(right > last) {
                        right = last;
                        left = last - 1 - (delta * 2);
                    }
                }

                for(let i = left; i < right; i++) {
                    pages.push(i);
                }
                pages.push(last);
            }
            
            return pages;
        }
    }
}
</script>
