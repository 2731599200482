<template>
  <div class="mt-2">
    <div class="row mb-2">
      <div class="col-md-6 offset-md-3 px-2">
        <form @submit.prevent="cambiarPassword">
          <div class="form-group row">
            <label for="password-actual" class="col-sm-4 col-form-label">Password actual</label>
            <div class="col-sm-8">
              <input type="password" class="form-control" id="password-actual" placeholder="Password actual" v-model="passwordActual" />
            </div>
          </div>
          <div class="form-group row">
            <label for="password-nuevo" class="col-sm-4 col-form-label">Password nuevo</label>
            <div class="col-sm-8">
              <input type="password" class="form-control" id="password-nuevo" placeholder="Password nuevo" v-model="passwordNuevo" />
            </div>
          </div>
          <div class="form-group row">
            <label for="password-confirmacion" class="col-sm-4 col-form-label">Confirmar password</label>
            <div class="col-sm-8">
              <input type="password" class="form-control" id="password-confirmacion" placeholder="Confirmar password" v-model="passwordConfrimacion" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-12">
              <button type="submit" class="btn btn-success">Guardar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import { url } from '@/services'
import { dialogs } from '../../services'

export default {
  data() {
    return {
      passwordActual: '',
      passwordNuevo: '',
      passwordConfrimacion: ''
    }
  },
  methods: {
    async cambiarPassword() {
      if (this.passwordNuevo === this.passwordConfrimacion) {
        try {
          await axios.post(url.create('/auth/change-password'), {
            password_actual: this.passwordActual,
            password_nuevo: this.passwordNuevo,
            password_confirmacion: this.passwordConfrimacion
          })
          this.passwordActual = ''
          this.passwordNuevo = ''
          this.passwordConfrimacion = ''
          this.$router.push({ name: 'start' })
        } catch (error) {
          PNotify.warning('Conflicto con los passwords')
        }
      } else {
        PNotify.warning('Los passwords no coinciden')
      }
    }
  }
}
</script>
