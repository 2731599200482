import main from './main'
import secured from './secured'

import Container from '../controllers/secured/container'

export default [
    ...main,
    {
        path: '/app',
        component: Container,
        meta: {
            secured: true
        },
        children: secured
    }
]